import "../css/footer.css";
import {AiFillGithub, AiOutlineInstagram, AiOutlineLinkedin} from 'react-icons/ai';
import {SiDevpost, SiLeetcode} from 'react-icons/si'; 
import {SlSocialSpotify} from 'react-icons/sl';
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip as ReactTooltip } from "react-tooltip";


const lightTheme = {
  color: 'black'
}

const darkTheme = {
  color: 'white'
}

function Footer({theme}) {
  console.log(theme)
  return (
    <div className="footer">
      <div className="footer-div1">
      <div className="footer-logo-wrapper">
      </div>
        <div className="footer-contact">
        
        </div>
      </div>
      <div className="footer-div2">
        <hr></hr>
      </div>
      <div className="footer-div3">
        <div style={theme === 'light' ? lightTheme : darkTheme}>2025 Sarina Li</div>
        <div>
        <div className="contact-phone-container">
            <a id="unique-link" href="tel:416-838-0998" className={theme === 'light' ? "contact-phone-light" : "contact-phone-dark"} data-tooltip-content="call me!">416 838-0998</a>
            <ReactTooltip anchorSelect={'phone'} />
        </div>
        <div className="contact-email-container">
            <a id="unique-link" href="mailto:sarinajin.li@gmail.com" className={theme === 'light' ? "contact-phone-light" : "contact-phone-dark"}  data-tooltip-content="email me!">Sarinajin.li@gmail.com</a>
            <ReactTooltip anchorSelect={'email'} />
        </div>
        </div>
        
      </div>
    </div>
  );
}

export default Footer;
