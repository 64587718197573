import React from "react";
import TechnologyBanners from "../classes/TechnologyBanners";
import GenAI1 from "../assets/projects_assets/emelleblocks/GenAI1.png";
import GenAI2 from "../assets/projects_assets/emelleblocks/GenAI2.png";

export default function EmelleBlocks() {
  return (
    <div className="trails-body">
      <div className="trails-title">Emelle Blocks</div>
      <div className="trails-date">April 2024</div>
      <div className="trails-tech">
        <img
          className="trails-tech-individ"
          src={TechnologyBanners.typescript}
          alt=""
        ></img>
        <img
          className="trails-tech-individ"
          src={TechnologyBanners.go}
          alt=""
        ></img>
      </div>
      <div className="trails-tagline">
        “Build and share simple GPT wrappers in 5 minutes."
      </div>
      <div className="trails-p">
        This project was a collective effort from{" "}
        <a href="https://github.com/zjayee">Jayee Zheng</a>,{" "}
        <a href="https://dorianchen.com/">Dorian Chen</a> and {" "}
        Sarina Li for our Y Combinator S24 application.
      </div>
      <div className="trails-p">
        Although we did not get in, a good amount of learning was done (at least on my part). The idea of the app is as follows: 
      </div>
      <div className="trails-tagline">
      Emelleblocks is a platform that lets users create AI applications with no code. Users can create and customize user interfaces for their machine learning models through a drag and drop builder, similar to Wix for websites. They can customize the backend logic with a flowchart builder, similar to Voiceflow. Anyone can build and deploy their own version of Chat-GPT or DALL-E in just 5 minutes.
        <br></br>
        Emelleblocks will also be a marketplace for AI apps where users can publish their applications, share via link, and discover applications built by others. For those who are looking for a solution, they can look at our community page to find pre-built applications that match their needs. It’s similar to Huggingface spaces.
        <br></br>
        There are a lot of interesting, upcoming features. For instance, we will be supporting multi-modal input and output so users can include audio, images, and files in their applications. This will let users make essentially anything that they want (ie. PDF summarizer, language learning app, audio transcriber app) using AI models. We will also support model fine tuning so users can make an application that is truly theirs.

      </div>
      <div className="trails-p">
        A big takeaway for me: I love building. I've spent wayyy too long working on things that are good for my career, not things that I genuinely enjoy. I want to return back to my roots of doing cool shit for the sake of doing cool shit, and building. 
      </div>
      <div className="trails-p">
        Though we did not get in, I think it's worth celebrating projects that took effort; we built a whole graph system for querying with a fleshed out graph builder and drag and drop editor on the frontend. I'm proud of it, regardless of the fact I didn't get in.
      </div>
      <div className="trails-prev-imgs">
        <img src={GenAI1} alt="" className="img-indiv-l"></img>
        <img src={GenAI2} alt="" className="img-indiv-l"></img>
      </div>
      <div className="trails-caption">
        views of Toronto at 6am after pulling an all nighter in the Rotman building to finish our MVP
      </div>
      <div className="trails-p">
        Demo Video: {" "}
        <a href="https://www.youtube.com/watch?v=v0yFuV06JIs">
        https://www.youtube.com/watch?v=v0yFuV06JIs
        </a>
      </div>
      <div className="trails-p">
        YC Founder Profile: {" "}
        <a href="https://www.youtube.com/watch?v=V5PPUWiq-FI">
        https://www.youtube.com/watch?v=V5PPUWiq-FI
        </a>
      </div>
      
    </div>
  );
}
